import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const AllWarranty = ({ userId }) => {

    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        const token = window.localStorage.getItem('token');
        try {
            const response = await fetch(`https://api.intimeglobal.ae/getUserWarranty`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                },
            });
            // if (!response.ok) {
            //     throw new Error('Failed to fetch user data');
            // }
            const data = await response.json();
            if (data.status === "ok") {
                setUserData(data.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setLoading(false);
        }
    };

    return (
        <div className="wrapper">
            <Sidebar />

            <div id="content">

                <Header />

                <div className="row">
                    <div className="col-xl-12">
                        {loading ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                <table width="100%" cellpadding="0" cellspacing="0" className="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Device ID</th>
                                            <th>Invoice No</th>
                                            <th>Date of Purchase</th>
                                            {/* <th>Warranty Start Date</th>*/}
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    {userData ? (
                                        <>
                                            <tbody>
                                                <tr>
                                                    <td>{userData.username}</td>
                                                    <td>{userData.email}</td>
                                                    <td>{userData.phone}</td>
                                                    <td>
                                                        {userData.devicedetails.map((device, index) => (
                                                            <div>{device.deviceId}</div>
                                                        ))}</td>
                                                    <td>{userData.invoiceno}</td>
                                                    <td>{userData.dateofPurchase}</td>
                                                    <td>
                                                        {userData.devicedetails.map((device, index) => (
                                                            <div key={index}>
                                                                <Link to={`/device-details/${device.deviceId}`}>
                                                                    <i className="fas fa-eye"></i>
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </td>
                                                    {/* <td>{userData.dateofPurchase}</td>
                                                <td>{userData.dateofPurchase}</td>
                                                <td>{userData.dateofPurchase}</td> */}
                                                </tr>
                                            </tbody>

                                            {/* 
                                        <div>

                                            <div>
                                                <div>Device ID</div>
                                                <div>Warranty Start Date</div>
                                                <div>Action</div>
                                            </div>

                                        </div> */}
                                            {/* <div>
                                            {userData.devicedetails.map((device, index) => (
                                                <div key={index}>
                                                    <div>{device.deviceId}</div>
                                                    <div>{new Date(device.warrantystartdate).toISOString().slice(0, 10)} </div>
                                                    <button onClick={() => ("ViewDeviceDetails", { deviceID: device.deviceId })}>
                                                        <i className="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            ))}
                                        </div> */}

                                        </>
                                    ) : (
                                        <div>No user data found.</div>
                                    )}
                                </table>
                                {/* <div className="row">
                                    <div className="col-xl-12 py-3 text-right">
                                        <Link to="../activate-warranty" className="btn btn-success">
                                            Activate New Warranty
                                        </Link>
                                    </div>
                                </div> */}
                            </>

                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllWarranty

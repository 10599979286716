import React, { useState } from 'react';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { useLocation, Link } from 'react-router-dom';


const TermsConditions = () => {

    let location = useLocation();
    console.log(location.state);

    const [isChecked, setIsChecked] = useState(false);
    const [invoiceno, setInvoiceNo] = useState('');
    const [dateofPurchase, setDateofPurchase] = useState('');
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        password: '',
        agreetnc: false,
    });

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { username, email, phone, password } = formData;
        try {
            if (!formData.agreetnc) {
                alert('Please agree to the terms and conditions');
                return;
            }

            await fetch('https://api.intimeglobal.ae/filldetailsinvoice', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    invoiceno,
                    dateofPurchase,
                    deviceId: location.state.deviceId,
                    imei: location.state.imei,
                    username,
                    email,
                    phone,
                    password,
                }),
            });

            alert('Warranty Activate Successfully');
            // navigation.navigate('AllWarranty');
        } catch (error) {
            console.error('Error updating details:', error);
            alert('Failed to update details. Please try again.');
        }
    };
    console.log(formData)

    return (
        <div className="wrapper">
            <div id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mx-auto">
                            <div className="row bg-light p-5 border">
                                <form>
                                    <div className="col-xl-12 pb-4">
                                        <h5>Activate Warranty</h5>
                                    </div>
                                    <div className="col-xl-12">
                                        <label className="font-weight-bold">Invoice Number</label>
                                        <input type='text' value={invoiceno} className="form-control" onChange={(e) => setInvoiceNo(e.target.value)} placeholder='Enter Invoice Number' />
                                    </div>
                                    <div className="col-xl-12 pt-3">
                                        <label className="font-weight-bold">Date of Purchase</label>
                                        <input type='date' className="form-control" value={dateofPurchase} onChange={(e) => setDateofPurchase(e.target.value)} placeholder='Date of Purchase' />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-user"></i></span>
                                        </div>
                                        <input
                                            type="text"
                                            id="username"
                                            className="form-control input_user"
                                            onChange={(e) => handleChange('username', e.target.value)}
                                            value={formData.username}
                                            placeholder="Enter Username"
                                            required
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-at"></i></span>
                                        </div>
                                        <input
                                            type="text"
                                            id="email"
                                            className="form-control input_user"
                                            value={formData.email}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                            placeholder="Enter Email"
                                            required
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-phone"></i></span>
                                        </div>
                                        <input
                                            type="text"
                                            id="phone"
                                            className="form-control input_user"
                                            value={formData.phone}
                                            onChange={(e) => handleChange('phone', e.target.value)}
                                            placeholder="Enter Phone"
                                            required
                                        />
                                    </div>
                                    <div className="input-group mb-2">
                                        <div className="input-group-append">
                                            <span className="input-group-text"><i className="fas fa-key"></i></span>
                                        </div>
                                        <input
                                            type="password"
                                            className="form-control input_user"
                                            placeholder="Password"
                                            id="password"
                                            value={formData.password}
                                            onChange={(e) => handleChange('password', e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-xl-12 pt-3 ml-4">
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            <Link to="#" data-toggle="modal" data-target="#termsactivate">I agree to the terms &amp; conditions</Link>
                                        </label>

                                        <div class="modal fade" id="termsactivate" tabindex="-1" role="dialog" aria-labelledby="termsactivateLabel" aria-hidden="true">
                                            <div class="modal-dialog" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">Terms &amp; Conditions</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>Terms and Conditions Text Here </p>
                                                        <div className="btn btn-success" onClick={(e) => setFormData({ ...formData, agreetnc: true })}>
                                                            Accept Terms
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                        <button type="button" class="btn btn-primary">Save changes</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-12 pt-3">
                                        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                                            Activate Warranty
                                        </button>
                                        {/* formData.agreetnc ? false : true  */}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsConditions

// Dashboard.js
import React from 'react';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {

    console.log(window.localStorage.getItem("token"));
    return (
        <div className="wrapper">
            <Sidebar />

            <div id="content">

                <Header />

                <h2>Dashboard</h2>

                <p>Content Here</p>
            </div>
        </div>
    );
};

export default Dashboard;

import React from 'react';
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Home from './pages/Home';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Nopage from './pages/Nopage';
import ActivateWarranty from './pages/ActivateWarranty';
import AllWarranty from './pages/AllWarranty';
import ViewDeviceDetails from './pages/ViewDeviceDetails';
import TermsConditions from './pages/TermsConditions';

function App() {
  const isLoggedIn = window.localStorage.getItem("loggedIn");

  return (
    <div>
      <BrowserRouter>
        <AuthProvider>
          {/* {isLoggedIn === "true" && <Sidebar /> || <Header />} */}

          <Routes>
            {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}
            <Route path="/" element={<ActivateWarranty />} />
            {/* <Route path="/login" element={<Home />} /> */}
            {/* <Route path="/register" element={<Register />} /> */}
            <Route
              path="/dashboard"
              element={
                isLoggedIn === "true" ? <Dashboard /> : <Navigate to="/login" />
              }
            />
            <Route path="/activate-warranty" element={isLoggedIn === "true" ? <ActivateWarranty /> : <Navigate to="/login" />} />
            <Route path="/all-warranty" element={isLoggedIn === "true" ? <AllWarranty /> : <Navigate to="/login" />} />
            <Route path="/device-details/:deviceId" element={isLoggedIn === "true" ? <ViewDeviceDetails /> : <Navigate to="/login" />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
            <Route path="*" element={<Nopage />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Header from '../components/header';

const ActivateWarranty = () => {

    const [deviceId, setDeviceId] = useState('');
    const [imei, setImei] = useState('');

    const navigate = useNavigate();


    const handleActivateWarranty = async () => {

        if (!deviceId || !imei) {
            alert('Please fill out both Device ID and IMEI Number.');
            return;
        }


        try {
            const response = await fetch('https://api.intimeglobal.ae/checkDeviceWarranty', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    deviceId: deviceId,
                    imei: imei,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message);
                // navigation.navigate("TermsConditions");
                // window.location.href("/termsconditions")
                console.log("yash");
                navigate("/termsconditions", { state: { deviceId: deviceId, imei: imei } });
            } else {
                alert(data.message || 'Device Id & IMEI Not Match');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error', 'An unexpected error occurred.');
        }

    };

    return (
        <div className="wrapper">
            <div id="content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mx-auto">
                            <div className="row bg-light p-5 border my-2">
                                <form>
                                    <div className="col-xl-12 pb-4">
                                        <h5>Fill the details</h5>
                                    </div>
                                    <div className="col-xl-12">
                                        <label className="font-weight-bold">Device ID</label>
                                        <input type='text' value={deviceId} className="form-control" onChange={(e) => setDeviceId(e.target.value)} placeholder='Enter Device Id' />
                                    </div>
                                    <div className="col-xl-12 pt-3">
                                        <label className="font-weight-bold">IMEI Number</label>
                                        <input type='text' className="form-control" value={imei} onChange={(e) => setImei(e.target.value)} placeholder='Enter IMEI Number' />
                                    </div>
                                    <div className="col-xl-12 pt-3">
                                        <button type="button" className="btn btn-primary" onClick={handleActivateWarranty}>
                                            Submit Details
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActivateWarranty
